export const customer = [
  {
    _id: "63701cc1f03239c72c00017f",
    name: "Konstantine",
    email: "kranstead0@narod.ru",
    password: "omMDCh",
    city: "Nurabelen",
    state: null,
    country: "ID",
    occupation: "Computer Systems Analyst I",
    phoneNumber: "8346315874",
    transactions: [
      "63701d74f0323986f3000158",
      "63701d74f03239d40b00007e",
      "63701d74f03239867500014b",
      "63701d74f032398675000152",
    ],
    role: "superadmin",
  },
  {
    _id: "63701cc1f03239c72c000180",
    name: "Marilyn",
    email: "mdonlon1@hostgator.com",
    password: "XRYBnKAfm",
    city: "Zhanghekou",
    state: null,
    country: "CN",
    occupation: "Food Chemist",
    phoneNumber: "9981906117",
    transactions: [
      "63701d74f03239b7f7000027",
      "63701d74f03239db69000153",
      "63701d74f03239569400002d",
      "63701d74f032394c4900014b",
    ],
    role: "user",
  },
  {
    _id: "63701cc1f03239c72c000181",
    name: "Olly",
    email: "oveneur2@marketwatch.com",
    password: "WwDjOlH",
    city: "Muang Sam Sip",
    state: null,
    country: "TH",
    occupation: "Staff Scientist",
    phoneNumber: "3868813669",
    transactions: [
      "63701d74f03239d40b00008c",
      "63701d74f03239b7f700001e",
      "63701d74f03239867500012e",
      "63701d74f03239db69000132",
      "63701d74f032390a34000340",
      "63701d74f03239d40b000087",
    ],
    role: "admin",
  },
  {
    _id: "63701cc1f03239c72c000182",
    name: "Hale",
    email: "hpyrah3@bbc.co.uk",
    password: "vojl4bBDJ",
    city: "San Luis",
    state: null,
    country: "AR",
    occupation: "Associate Professor",
    phoneNumber: "8535391908",
    transactions: [
      "63701d74f032390a3400032f",
      "63701d74f032390a3400033f",
      "63701d74f03239d59100034c",
      "63701d74f03239569400001a",
      "63701d74f03239c72c0001a0",
      "63701d74f032399c0000013d",
      "63701d74f03239db69000152",
      "63701d74f03239b91300001c",
      "63701d74f03239cdc500003f",
      "63701d74f03239bef0000159",
    ],
    role: "superadmin",
  },
  {
    _id: "63701cc1f03239c72c000183",
    name: "Allie",
    email: "afranzschoninger4@simplemachines.org",
    password: "zocih1DjIv",
    city: "Osieck",
    state: null,
    country: "PL",
    occupation: "Senior Cost Accountant",
    phoneNumber: "5367727534",
    transactions: [
      "63701d74f032395694000036",
      "63701d74f032395b33000138",
      "63701d74f032394441000314",
    ],
    role: "superadmin",
  },
  {
    _id: "63701cc1f03239c72c000184",
    name: "Donelle",
    email: "dcrossgrove5@constantcontact.com",
    password: "Q81bu6JV",
    city: "São Jerônimo",
    state: null,
    country: "BR",
    occupation: "Chemical Engineer",
    phoneNumber: "8601650433",
    transactions: [
      "63701d74f03239d40b000078",
      "63701d74f03239db69000157",
      "63701d74f03239b7f7000025",
      "63701d74f032395694000042",
      "63701d74f03239d591000339",
      "63701d74f03239b7f700003d",
      "63701d74f032396b8e000029",
      "63701d74f03239d81e00003e",
      "63701d74f03239d81e000032",
      "63701d74f03239db6900013d",
    ],
    role: "user",
  },
  {
    _id: "63701cc1f03239c72c000185",
    name: "Westbrook",
    email: "wsiddon6@state.tx.us",
    password: "PVfV72mNxb4",
    city: "Hanjiashu",
    state: null,
    country: "CN",
    occupation: "Quality Engineer",
    phoneNumber: "3096621613",
    transactions: [
      "63701d74f0323944410002fa",
      "63701d74f03239f09e0001ab",
      "63701d74f03239db6900014e",
      "63701d74f032395b33000158",
      "63701d74f032390a3400034c",
      "63701d74f0323986f3000151",
      "63701d74f0323986f3000145",
      "63701d74f03239867500012f",
      "63701d74f03239f09e0001ad",
    ],
    role: "superadmin",
  },
];

export const t = {
  title: "ID",
  field: "id",
  editable: false,
  title: "Full Name",
  field: "fullName",
  title: "Email",
  field: "email",
  title: "Username",
  field: "username",
};

export const taskTypeDropDown = [
  { id: "1", type: "Task List" },
  { id: "2", type: "Schedule" },
  { id: "3", type: "Grocery List" },
];

export const tasks = [
  {
    id: "1",
    type: "Task List",
    task: "Fix bed",
  },
  {
    id: "2",
    type: "Task List",
    task: "Fold blankets",
  },
  {
    id: "3",
    type: "Task List",
    task: "Laundry",
  },
  {
    id: "4",
    type: "Task List",
    task: "Sweep",
  },
  {
    id: "5",
    type: "Task List",
    task: "Mop",
  },
  {
    id: "6",
    type: "Task List",
    task: "Clean Playroom",
  },
  {
    id: "7",
    type: "Task List",
    task: "Feed dog",
  },
  {
    id: "8",
    type: "Task List",
    task: "Cut grass",
  },
  {
    id: "9",
    type: "Task List",
    task: "Wash dishes",
  },
  {
    id: "10",
    type: "Task List",
    task: "Put up groceries",
  },
  {
    id: "11",
    type: "Task List",
    task: "Other",
  },
  {
    id: "12",
    type: "Schedule",
    task: "Grocery Store",
  },
  {
    id: "13",
    type: "Schedule",
    task: "Post Office",
  },
  {
    id: "14",
    type: "Schedule",
    task: "Lunch",
  },
  {
    id: "15",
    type: "Schedule",
    task: "Park",
  },
  {
    id: "16",
    type: "Schedule",
    task: "Cleaners",
  },
  {
    id: "17",
    type: "Schedule",
    task: "Movies",
  },
  {
    id: "18",
    type: "Schedule",
    task: "Other",
  },
  {
    id: "19",
    type: "Grocery List",
    task: "Tomatoes",
  },
  {
    id: "20",
    type: "Grocery List",
    task: "Bread",
  },
  {
    id: "21",
    type: "Grocery List",
    task: "Ham",
  },
  {
    id: "22",
    type: "Grocery List",
    task: "Toilet paper",
  },
  {
    id: "23",
    type: "Grocery List",
    task: "Dog food",
  },
  {
    id: "24",
    type: "Grocery List",
    task: "Other",
  },
];

export const securityQuestions = [
  { id: 1, question: "What is your mother's maiden name?" },
  { id: 2, question: "What is the name of your first pet?" },
  { id: 3, question: "What was your first car?" },
  { id: 4, question: "What elementary school did you attend?" },
  { id: 5, question: "What is the name of the town where you were born?" },
];
